import { useRef } from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button,
    Row,
    Col
} from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function UploadFile({ setFile, file, submitFile }) {

    function onFileChange(e) {
        const target = e.target
        if (target.files.length > 0) {
            setFile(target.files[0])
        }
    }

    function confirmUploadFile() {
        confirmAlert({
            title: "Are you sure you want to import new file?",
            message: `Please save changes you made before importing a new file, all unsaved changes will be removed.`,
            buttons: [
                {
                    label: 'Import file',
                    className: 'btn btn-success',
                    onClick: () => {
                        submitFile(file)
                        setFile(null)
                    }
                },
                {
                    label: 'Cancel',
                    className: 'btn btn-danger'
                }
            ]
        })
    }
    

    return (
        <>
            <Form>
                <Row className="align-items-center mx-0">
                <Col>
                    <FormGroup>
                        <Label for="ReconciliationFile">
                            Select File
                        </Label>
                        <Input
                            id="ReconciliationFile"
                            name="file"
                            type="file"
                            accept=".csv"
                            onChange={onFileChange}
                        />
                        <FormText>
                            Please upload a .csv file, other formats are not supported currently.
                        </FormText>
                    </FormGroup>
                </Col>
                <Col>
                    { file && 
                        <Button color="secondary" onClick={ confirmUploadFile }>
                            Import
                        </Button>
                    }
                </Col>
                </Row>
            </Form>
        </>
    )
}