import { Link } from 'react-router-dom';

import {
    Nav,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Navbar,
    NavbarBrand
} from 'reactstrap';

import { ROUTES } from '../../configs/routeConfig';

import './SideMenu.css';

export default function SideMenu() {

    return (
        <div className="side-menu-wrapper bg-secondary col-2">
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3" light>
                <NavbarBrand tag={Link} to={ROUTES.HOME} className="text-white font-weight-bold" >
                    OTA Admin
                </NavbarBrand>
            </Navbar>
            <Nav pills vertical>
                <NavLink className="text-white" tag={Link} to={ROUTES.OTA_PUBLISHING}>
                    OTA Publishing
                </NavLink>
                <Dropdown nav inNavbar isOpen={true} toggle={ () => true}>
                    <DropdownToggle nav className="text-white bg-secondary">
                        Booking.com Reconciliation
                    </DropdownToggle>
                    <DropdownMenu className="w-100 rounded-0 border-0 py-0">
                        <DropdownItem>
                            <NavLink className="text-white py-2" tag={Link} to={ROUTES.RECONCILIATION.PENDING_PAYMENTS}>
                                Pending Payments
                            </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                            <NavLink className="text-white py-2" tag={Link} to={ROUTES.RECONCILIATION.COMPLETED_PAYMENTS}>
                                Completed Payments
                            </NavLink>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Nav>
        </div>
    )
}
