import {  useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs'

import {
    Button,
    Row,
    Col,
    FormGroup,
    Input,
    FormFeedback
} from 'reactstrap';
import { CSVLink } from "react-csv";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


import { reconciliationTableHeading, disputesExportHeading } from '../../../configs/reonciliationTableConfig'
import { findReconcilationDisputesData } from '../../../helpers/reconciliationHelpers';

import CustomTable from '../../Table/CustomTable';
import SavePaymentAlert from '../../Alert/SavePaymentAlert';


export default function PendingPaymentsTable({ reconciliationData, saveReconciliationTableData, saveReconciliationPaymentData, isChangesMade, clearReconciliationTableData }) {
    const paymentRef = useRef(null)

    const [disputesData, setDisputesData] = useState([])

    const totalCommissionPayable = useMemo(() => {
        const sum = reconciliationData
            .filter(obj => obj?.isMatched)
            .reduce((accumulator, currentObject) => {
                return accumulator + currentObject?.commisionAmount;
            }, 0);

        return sum;
    }, [reconciliationData])

    function confirmSavePayment() {
        const paymentData = reconciliationData.filter((item) => item.isMatched)
        const ref = paymentRef.current.value

        if (paymentData.length > 0 && ref !== "") {
            confirmAlert({
                customUI: ({ onClose }) =>
                    <SavePaymentAlert
                        title="Are you sure you want to save the payment?"
                        message={`Payment ref: ${ paymentRef.current.value }`}
                        onSave={(done) => saveReconciliationPaymentData(paymentRef.current.value, totalCommissionPayable, done)}
                        onClose={onClose}
                        paymentData={paymentData}
                    />
            })
        } else {
            confirmAlert({
			    title: ref === "" ? "Please enter the payment reference" : "No records selected for saving the payment",
			    buttons: [
				    {
					    label: 'Cancel',
					    className: 'btn btn-danger'
				    }
			    ]
		    })
        }
    }


    function confirmClearReconciliationTableData() {
        confirmAlert({
            title: "Clear all reconciliation data",
            message: 'By clearing data, all the changes you made in this page will be lost.',
            buttons: [
                {
					label: 'Clear all',
                    className: 'btn btn-secondary',
                    onClick: () => clearReconciliationTableData()
				},
				{
					label: 'Cancel',
					className: 'btn btn-danger'
				}
			]
		})
    }

    function formatCurrency(amount) {
        return new Intl.NumberFormat('en-DE', { style: 'currency', currency: 'EUR' }).format(amount.toFixed(2))
    }

    return (
        <div>
            <Row className="mb-4">
                <Col>
                    <div className="d-flex flex-row justify-content-end">
                        <Button color="secondary" className="mx-2">
                            <CSVLink
                                data={disputesData}
                                onClick={() => setDisputesData(findReconcilationDisputesData(reconciliationData))}
                                headers={disputesExportHeading}
                                filename={`Booking_Com Disputes ${dayjs().format('YYYYMMDD HHmmss')}.csv`}
                                className="text-white">
                                    Export Disputes
                            </CSVLink>
                        </Button>
                        {isChangesMade &&
                            <Button className="mx-2" color="secondary" onClick={saveReconciliationTableData}>
                                Save Changes
                            </Button>
                        }
                        { reconciliationData && reconciliationData.length > 0 &&
                            <Button className="mx-2" color="danger" onClick={confirmClearReconciliationTableData}>
                                Clear all
                            </Button>
                        }
                    </div>
                </Col>
            </Row>
            <Row style={{marginBottom: '70px'}}>
                <CustomTable headData={reconciliationTableHeading} bodyData={reconciliationData} highlight={'isMatched'} />
            </Row>
            <Row className="fixed-bottom p-2 bg-white" style={{ left: '16.6%', zIndex: '10'}} >
                <Col >
                    <h5> Total Commission Payable: { formatCurrency(totalCommissionPayable) } </h5>
                </Col>
                <Col className="col-3">
                    <FormGroup>
                        <Input
                            id="ReconciliationPayment"
                            name="reconciliationPayment"
                            type="text"
                            placeholder="Payment Reference"
                            innerRef={paymentRef}
                        />
                        <FormFeedback>
                            Something went wrong please try again, if the issue persists please contact the dev team.
                        </FormFeedback>
                    </FormGroup>
                </Col>
                <Col className="col-2">
                    <Button color="secondary" className="text-white" onClick={confirmSavePayment}>
                        Save Payment
                    </Button>
                </Col>
            </Row>
        </div>
    )
}
