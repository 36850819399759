import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const msalConfig = {
	auth: {
		clientId: window.location.hostname === "ota-admin.villaplus.com" ? "68709e84-2c5b-4204-961e-dda6842d1d03" : "6b846df9-3aa9-4a65-941e-b39ef4c6f44b"
	}
};

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<BrowserRouter basename={baseUrl}>
		<MsalProvider instance={msalInstance}>
			<App />
		</MsalProvider>
	</BrowserRouter>
);