import SideMenu from '../../components/SideMenu/SideMenu';
import './Layout.css';

export default function Layout({ children})  {

    return (
        <div className="d-flex flex-row layout-wrapper">
            <SideMenu />
            <div className="col position-relative">
                { children }
            </div>
        </div>
    )
}
