import {  useEffect, useState } from 'react';
import dayjs from 'dayjs'

import { Row, Col, Button } from 'reactstrap';
import { CSVLink } from 'react-csv';

import { paymentExportHeading, reconciliationPaymentReferencesTableHeading, reconciliationPaymentTableHeading } from '../../../configs/reonciliationTableConfig'

import CustomTable from '../../Table/CustomTable';


export default function CompletedPaymentsTable({ reconciliationPaymentReferences, searchedReconciliationPayment, reconciliationPaymentData, getPaymentData, currentPaymentRef }) {
    const [displayedPaymentRefs, setDisplayedPaymentRefs] = useState([]);

    useEffect(() => {
        if (searchedReconciliationPayment != null)
            setDisplayedPaymentRefs(reconciliationPaymentReferences.filter(item => item.paymentRef === searchedReconciliationPayment))
        else {
            setDisplayedPaymentRefs(reconciliationPaymentReferences);
        }
    }, [searchedReconciliationPayment]);

    return (
        <div>
            <Row>
                <CustomTable
                    headData={reconciliationPaymentReferencesTableHeading}
                    bodyData={displayedPaymentRefs}
                    onRowClick={getPaymentData}
                />
            </Row>
            {reconciliationPaymentData &&
                <>
                    <Row className="mt-4">
                        <h3>Payment Breakdown</h3>
                    </Row>

                    <Row className="mb-1 align-items-center">
                        <Col>
                            <h6>Payment Refernce: { currentPaymentRef }</h6>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button color="secondary">
                                <CSVLink
                                    data={reconciliationPaymentData}
                                    headers={paymentExportHeading}
                                    filename={`Booking_Com Invoices ${dayjs().format('YYYYMMDD HHmmss')}.csv`}
                                    className="text-white"
                                >
                                    Export Payment
                                </CSVLink>
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <CustomTable
                            headData={reconciliationPaymentTableHeading}
                            bodyData={reconciliationPaymentData}
                        />
                    </Row>
                </>
            }
        </div>
    )
}
