import 'react-confirm-alert/src/react-confirm-alert.css';

import dayjs from 'dayjs'
import { CSVLink } from 'react-csv';

import { paymentExportHeading } from '../../configs/reonciliationTableConfig'

import { Button } from 'reactstrap';


export default function SavePaymentAlert({ title, message, onClose, onSave, paymentData }) {
    return (
        <div className='react-confirm-alert-body' style={{maxWidth: '560px'}}>
            <h1>{title}</h1>
            <p>{message}</p>
            <div className="d-flex">
                <Button color="secondary" style={{marginRight: '16px'}}>
                    <CSVLink
                        data={paymentData}
                        onClick={(event, done) => {
                            onSave(done)
                            onClose()
                        }}
                        headers={paymentExportHeading}
                        filename={`Booking_Com Invoices ${dayjs().format('YYYYMMDD HHmmss')}.csv`}
                        className="text-white"
                    >
                        Export and Save Payment
                    </CSVLink>
                </Button>
                <Button
                    color="secondary"
                    style={{ marginRight: '16px' }}
                    onClick={() => {
                        onSave(null)
                        onClose()
                    }}
                >
                    Save Payment
                </Button>
                <Button
                    color="danger"
                    onClick={() => { onClose() }}>
                    Cancel
                </Button>
            </div>
      </div>
    );
}