import { useState, useEffect } from 'react';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { Row, Spinner } from 'reactstrap';

import useAuth from '../../../hooks/authenticationHooks';
import { prepareReconciliationData, findReconcilationChanges, findReconcilationPaymentData } from '../../../helpers/reconciliationHelpers'
import { RECONCILIATION_API_ROUTES } from '../../../configs/apiConfig'


import UploadFile from '../../../components/Reconciliation/UploadFile/UploadFile';
import PendingPaymentsTable from '../../../components/Reconciliation/PendingPaymentsTable/PendingPaymentsTable';
import StatusAlert from '../../../components/Alert/StatusAlert/StatusAlert';


export default function PendingPayments() {
	const [reconciliationData, setReconciliationData] = useState(null)
	const [changedReconciliationData, setChangedReconciliationData] = useState([])
	const [file, setFile] = useState(null);

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)

	const { username, userAccessToken } = useAuth()

	useEffect(() => {
		if (userAccessToken) {
			getReconciliationTableData();
		}
	}, [userAccessToken]);

	async function getReconciliationTableData() {
		setLoading(true)
		setError(false)
		try {
			const response = await fetch(RECONCILIATION_API_ROUTES.RECONCILIAITION.GET_ALL, {
				method: 'GET',
				cache: 'no-cache',
				headers: {
					"X-Authorization": userAccessToken,
					"X-User": username,
				}
			})
			if (!response.ok) {
				const errorMessage = await response.text()
				throw new Error(errorMessage)
			}
			const data = await response.json()
			storeReconciliationData(data)
		} catch (error) {
			setError(true)
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	async function uploadReconciliationData(file) {
		setLoading(true)
		setError(false)
        setReconciliationData(null)
        setChangedReconciliationData([])

        const formData = new FormData();
        formData.append("file", file)

		try {
			const response = await fetch(RECONCILIATION_API_ROUTES.RECONCILIAITION.UPLOAD, {
				method: 'POST',
				cache: 'no-cache',
				headers: {
					"X-Authorization": userAccessToken,
					"X-User": username
				},
				body: formData
			})
			if (!response.ok) {
				const errorMessage = await response.text()
				throw new Error(errorMessage)
			}
			const data = await response.json()
            storeReconciliationData(data)
		} catch (error) {
			setError(true)
			setFile(null)
		    console.error(error)
        } finally {
			setLoading(false)
        }
    }

	async function saveReconciliationTableData() {
		setLoading(true)
		setError(false)
        setChangedReconciliationData([])
        const updatedReconciliationData = findReconcilationChanges(reconciliationData, changedReconciliationData)

		try {
			const response = await fetch(RECONCILIATION_API_ROUTES.RECONCILIAITION.SAVE, {
				method: 'POST',
				cache: 'no-cache',
				headers: {
					"X-Authorization": userAccessToken,
					"X-User": username,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(updatedReconciliationData) 
			})
			if (!response.ok) {
				const errorMessage = await response.text()
				throw new Error(errorMessage)
			}
		} catch (error) {
			setError(true)
		    console.error(error)
        } finally {
			setLoading(false)
        }
	}

	async function clearReconciliationTableData() {
		setLoading(true)
		setError(false)

		try {
			const response = await fetch(RECONCILIATION_API_ROUTES.RECONCILIAITION.CLEAR, {
				method: 'POST',
				cache: 'no-cache',
				headers: {
					"X-Authorization": userAccessToken,
					"X-User": username,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(reconciliationData.map( item => item.reservationId)) 
			})

			if (!response.ok) {
				const errorMessage = await response.text()
				throw new Error(errorMessage)
			}

			if (response.status === 200) {
				setChangedReconciliationData([])
				setReconciliationData(null)
			}
		} catch (error) {
			setError(true)
		    console.error(error)
        } finally {
			setLoading(false)
        }
	}

	async function saveReconciliationPaymentData(ref, amount, done) {
		setReconciliationData(null)
		setLoading(true)
		setError(false)
		
		const { matchedPaymentsData, pendingPaymentData } = findReconcilationPaymentData(reconciliationData)

		const paymentData = {
			PaymentRef: ref,
			CheckedDate: new Date(),
			CheckedBy: username,
			Amount: amount,
			PaymentBreakdown: matchedPaymentsData
		}

		const body = {
			paymentData,
			pendingPaymentData
		}

		try {
			const response = await fetch(RECONCILIATION_API_ROUTES.RECONCILIAITION_PAYMENT.SAVE, {
				method: 'POST',
				cache: 'no-cache',
				headers: {
					"X-Authorization": userAccessToken,
					"X-User": username,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(body),
			})
			if (!response.ok) {
				const errorMessage = await response.text()
				done && done(false)
				throw new Error(errorMessage)
			}
			done && done(true)
			storeReconciliationData(pendingPaymentData)
		} catch (error) {
			setError(true)
			console.error('Error:', error)
		} finally {
			setLoading(false)
		}
    }

	function storeReconciliationData(data) {
		setChangedReconciliationData([])
		setReconciliationData(prepareReconciliationData(data, changeReconciliationStatus, addReconciliationNote))
	}

	function changeReconciliationStatus(id, status) {
		setChangedReconciliationData((prev) => [...prev, id])
        setReconciliationData((data) => data.map((item) => {
            if (item.id === id)
                item.isMatched = status
            return item
        }))
	}

	function addReconciliationNote(id, value) {
		setChangedReconciliationData((prev) => [...prev, id])
		setReconciliationData((data) => data.map((item) => {
            if (item.id === id)
                item.notes = value
            return item
        }))
	}

	return (
		<>
			<h1 className="mb-3 ml-2">Booking.com Pending Payments</h1>
			<AuthenticatedTemplate interactionType={InteractionType.Redirect}>
				<Row>
					<UploadFile file={file} setFile={setFile} submitFile={ uploadReconciliationData }/>
				</Row>
				<Row>
					{ loading && <Spinner color="secondary" type="grow" className="position-absolute m-auto" style={{ inset: '0', width: '150px', height: '150px'}}  /> }
					{ reconciliationData &&
						<PendingPaymentsTable
							saveReconciliationTableData={saveReconciliationTableData}
							saveReconciliationPaymentData={saveReconciliationPaymentData}
							clearReconciliationTableData={clearReconciliationTableData}
							reconciliationData={reconciliationData}
							isChangesMade={changedReconciliationData?.length > 0}
						/>
					}
				</Row>
				<StatusAlert type="danger" show={error} hide={setError} />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<p>You need to sign in to see this page. Please wait for a redirect to the sign in page.</p>
			</UnauthenticatedTemplate>
		</>
	);
}