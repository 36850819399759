import { Table } from 'reactstrap';

export default function CustomTable({ headData, bodyData, highlight = null, onRowClick = null }) {

    const highlightColor = highlight !== null

    function formatCurrency(format, item) {
        return new Intl.NumberFormat('en-DE', { style: 'currency', currency: format }).format(item)
    }

    return (
        <Table
            bordered
            responsive
            size="sm"
            style={{fontSize: '14px'}}
        >
            <thead>
                <tr className="bg-secondary text-white">
                    <th key="slno">Sl No.</th>
                    { headData.map((item) => 
                        <th key={ item.key }>{ item.label }</th>
                    )}
                </tr>
            </thead>
            <tbody>
                { bodyData === null || bodyData?.length === 0 && <tr><td colSpan={ headData.length + 1} >No data available</td></tr> }
                { bodyData.map((rowData, index) =>
                    <tr
                        key={index}
                        onClick={() => onRowClick ? onRowClick(rowData) : ''} 
                        className={`${ highlightColor ? rowData[highlight] ? '' : 'bg-danger text-white' : '' } ${ onRowClick ? 'cursor-pointer' : '' }`}
                    >
                        <td key="slno">{ index + 1 }</td>
                        { headData.map((item) => 
                            <td key={item.key}>
                                { item?.format && rowData[item.key] !== ""  ? formatCurrency(item?.format, rowData[item.key]) : rowData[item.key] }
                            </td>
                        )}
                    </tr>
                )}
            </tbody>
        </Table>
    )

}

