import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { Input } from 'reactstrap';
import EasyEdit from 'react-easy-edit';

dayjs.extend(customParseFormat)


export function prepareReconciliationPaymentReferenceData(reconciliationPaymentRefData) {
    const res = reconciliationPaymentRefData.map((item) => {
        item.checkedDate = dayjs(item.checkedDate).format('DD/MM/YYYY')
        return item
    })
    return res
}

export function prepareReconciliationPaymentData(reconciliationPaymentData) {
    const res = reconciliationPaymentData.map((item) => {
        item.arrival = dayjs(item.arrival).format('DD/MM/YYYY')
        return item
    })
    return res
}

export function prepareReconciliationData(data, changeReconciliationStatusFn, saveNoteFn) {
    const res = data.map((item) => {
        item.checkInDate = item.bookingRef ? dayjs(item.checkIn).format('DD/MM/YYYY') : ""
        item.arrivalDate =  dayjs(item.arrival).format('DD/MM/YYYY')
        item.departureDate =  dayjs(item.arrival).format('DD/MM/YYYY')
        item.editableNote =
            <EasyEdit
                type="text"
                value={item.notes}
                onSave={(val) => saveNoteFn(item.id, val)}
                saveButtonLabel="Save"
                className="cursor-pointer"
            />
        item.pay =
            <Input
                type="checkbox"
                className="cursor-pointer"
                defaultChecked={item.isMatched}
                onChange={(e) => changeReconciliationStatusFn(item.id, e.target.checked)}
            />
        return item
    })
    return res.sort(sortByDateAndReference)
}

function sortByDateAndReference(a, b) {
    const firstDate = dayjs(a.checkIn)
    const secondDate = dayjs(b.checkIn)
    if (firstDate.isSame(secondDate)) {
        return a.bookingRef ? a.bookingRef.localeCompare(b.bookingRef) : 0
    }
    else if (firstDate.isBefore(dayjs(secondDate))) {
        return -1
    } else {
        return 1
    }
}

export function findReconcilationChanges(reconciliationData, changedReconciliationData) {
    const updatedReconciliationData = []
    const changedReconciliationReservations = new Set(changedReconciliationData)

    changedReconciliationReservations.forEach((id) => {
        reconciliationData.forEach((item) => {
            if (item.id === id) { 
                const newItem = Object.assign({}, item)
                delete newItem.pay
                delete newItem.checkInDate
                delete newItem.arrivalDate
                delete newItem.departureDate
                delete newItem.editableNote
                updatedReconciliationData.push(newItem)
            }
        })
    })

    return updatedReconciliationData;
}

export function findReconcilationDisputesData(reconciliationData) {
    const disputesData = []

    reconciliationData.forEach((item) => {
        if (!item.isMatched) { 
            const newItem = Object.assign({}, item)
            delete newItem.pay
            delete newItem.isMatched
            delete newItem.reason
            delete newItem.checkInDate
            delete newItem.editableNote
            disputesData.push(newItem)
        }
    })

    return disputesData 
}

export function findReconcilationPaymentExportData(reconciliationData) {
    const matchedPaymentsData = []

    reconciliationData.forEach((item) => {
        if (item.isMatched) { 
            const newItem = {}
            newItem.invoiceNumber = item.invoiceNumber
            newItem.hotelId = item.hotelId
            newItem.propertyName = item.propertyName
            newItem.commisionAmount = item.commisionAmount
            matchedPaymentsData.push(newItem)
        }
    })

    return matchedPaymentsData 
}

export function findReconcilationPaymentData(reconciliationData) {
    const matchedPaymentsData = []
    const pendingPaymentData = []

    reconciliationData.forEach((item) => {
        const newItem = Object.assign({}, item)
        delete newItem.pay
        delete newItem.checkInDate
        delete newItem.arrivalDate
        delete newItem.departureDate
        delete newItem.editableNote
        if (item.isMatched) {
            delete newItem.isMatched
            delete newItem.reason
            matchedPaymentsData.push(newItem)
        } else {
            pendingPaymentData.push(newItem)
        }
    })

    return { matchedPaymentsData, pendingPaymentData }
}

