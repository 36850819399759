import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import SortingArrow from '../components/SortingArrow';

class AccommodationsTable extends Component {
	constructor(props) {
		super(props);
		this.state = ({
			appliedSorting: {
				direction: 'desc',
				column: 'dateModified'
			}
		});

		this.forceInventoryUpdate = this.forceInventoryUpdate.bind(this);
		this.deleteInventoryRecord = this.deleteInventoryRecord.bind(this);
		this.confirmDelete = this.confirmDelete.bind(this);
		this.startPublishing = this.startPublishing.bind(this);
		this.applySorting = this.applySorting.bind(this);
	}

	async forceInventoryUpdate(resource) {
		await fetch('accommodations/forceupdate', {
			method: 'POST',
			cache: 'no-cache',
			headers: {
				'X-Authorization': this.props.userAccessToken,
				'X-User': this.props.username,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(resource)
		})
			.then(response => response.json())
			.then(success => {
				if (success === true) {
					this.props.showInfo(
						"Accommodation update forced successfully. Please allow up to 20 minutes for the accommodation " +
						"to be updated in the Rentals United Portal.");
				}
			})
			.catch((error) => {
				this.props.showError(error);
			});
	}

	async startPublishing(resource) {
		await fetch('accommodations/startpublishing', {
			method: 'POST',
			cache: 'no-cache',
			headers: {
				'X-Authorization': this.props.userAccessToken,
				'X-User': this.props.username,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(resource)
		})
			.then(response => response.json())
			.then(success => {
				if (success === true) {
					this.props.showInfo(
						"Accommodation will now once again be published to Rentals United.");
					this.props.getAccommodations();
				}
			})
			.catch((error) => {
				this.props.showError(error);
			});
	}

	async confirmDelete(accommodation) {
		confirmAlert({
			title: "Are you sure you want to stop publishing Villa " + accommodation.name + "?",
			message: "We will stop sending updates for this villa but it will not be removed from Rentals United or OTAs.",
			buttons: [
				{
					label: 'Yes',
					className: 'btn btn-success',
					onClick: () => this.deleteInventoryRecord(accommodation.link)
				},
				{
					label: 'No',
					className: 'btn btn-danger'
				}
			]
		});
	}

	async deleteInventoryRecord(resource) {
		await fetch('accommodations/stoppublishing', {
			method: 'DELETE',
			cache: 'no-cache',
			headers: {
				'X-Authorization': this.props.userAccessToken,
				'X-User': this.props.username,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(resource)
		})
			.then(response => response.json())
			.then(success => {
				if (success === true) {
					this.props.showInfo(
						"Accommodation record was successfully stopped from being published. We will not send updates " +
						"or try to publish it to Rentals United anymore, but it was not deleted from the RU Portal or OTAs. " +
						"Please make sure to delete it from there as well to finalize the process.");
					this.props.getAccommodations();
				}
			})
			.catch((error) => {
				this.props.showError(error);
			});
	}

	applySorting(direction, column) {
		const sortingOptions = {
			directions: ['asc', 'desc'],
			columns: ['name', 'centreName', 'dateModified', 'isPublishingStopped']
		};

		if (sortingOptions.directions.indexOf(direction) < 0 ||
			sortingOptions.columns.indexOf(column) < 0) {
			console.error('[' + column + ' + ' + direction + '] is not an allowed sorting option');
			return;
		}

		this.setState({
			appliedSorting: {
				direction: direction,
				column: column
			}
		});
	}

	renderAccommodationsTable(accommodations) {
		const sorting = this.state.appliedSorting;

		return (
			<table className='table table-striped' aria-labelledby="tabelLabel">
				<thead>
					<tr className="table-header-row">
						<th>
							<div className="w-100 d-flex justify-content-between align-items-center">
								Name
								<SortingArrow
									appliedSorting={sorting}
									name="name"
									callback={this.applySorting}
								/>
							</div>
						</th>
						<th>
							Rentals United ID
						</th>
						<th>
							<div className="w-100 d-flex justify-content-between align-items-center">
								Centre
								<SortingArrow
									appliedSorting={sorting}
									name="centreName"
									callback={this.applySorting}
								/>
							</div>
						</th>
						<th>
							<div className="w-100 d-flex justify-content-between align-items-center">
								Publishing Stopped
								<SortingArrow
									appliedSorting={sorting}
									name="isPublishingStopped"
									callback={this.applySorting}
								/>
							</div>
						</th>
						<th>
							<div className="w-100 d-flex justify-content-between align-items-center">
								Date Modified
								<SortingArrow
									appliedSorting={sorting}
									name="dateModified"
									callback={this.applySorting}
								/>
							</div>
						</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{accommodations && accommodations.length > 0 ? accommodations.map(accommodation =>
						<tr key={accommodation.link} className={accommodation.isPublishingStopped ? "deleted-row" : ""}>
							<td>{accommodation.name.trim()}</td>
							<td>{accommodation.channelManagerId}</td>
							<td>{accommodation.centreName}</td>
							<td align="center">{accommodation.isPublishingStopped ? "YES" : "NO"}</td>
							<td>{accommodation.formattedDate}</td>
							<td align="right">
								{!accommodation.isPublishingStopped &&
									<>
										<button
											className="btn btn-primary"
											onClick={() => this.forceInventoryUpdate(accommodation.link)}
										>Force Update</button>
										<button
											className="btn btn-danger ms-2"
											onClick={() => this.confirmDelete(accommodation)}
										>Stop Publishing</button>
									</>
								}
								{accommodation.isPublishingStopped &&
									<>
										<button
											className="btn btn-success"
											onClick={() => this.startPublishing(accommodation.link)}
										>Start Publishing</button>
									</>
								}
							</td>
						</tr>
					) : <tr>
							<td colSpan="3">There are no accommodations to show.</td>
						</tr>
					}
				</tbody>
			</table>
		);
	}

	render() {
		const appliedSorting = this.state.appliedSorting;
		let publishedAccommodations = this.props.publishedAccommodations;

		if (publishedAccommodations) {
			publishedAccommodations = publishedAccommodations.sort((a, b) => {
				if (a[appliedSorting.column] < b[appliedSorting.column]) {
					return (appliedSorting.direction === "asc" ? -1 : 1);
				}
				if (a[appliedSorting.column] > b[appliedSorting.column]) {
					return (appliedSorting.direction === "asc" ? 1 : -1);
				}
				return 0;
			});
		}

		return (
			<div className="accommodations-table-wrap mb-3">
				<h6 className="text-success"><b>Note: </b>When publishing or updating a villa that has availability more than three years from now, the availability will be cut off and only the dates that are within the three year range will be sent to Rentals United</h6>
				<h5>Accommodations published to Rentals United</h5>
				{publishedAccommodations && this.renderAccommodationsTable(publishedAccommodations)}
			</div>
		);
	}
}

export default AccommodationsTable;