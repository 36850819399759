import './CompletedPayments.css';
import { useState, useEffect } from 'react';
import SelectSearch from 'react-select-search';
import { Spinner } from 'reactstrap';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import fuzzySearch from '../../../helpers/fuzzySearch';
import useAuth from '../../../hooks/authenticationHooks';
import { prepareReconciliationPaymentData, prepareReconciliationPaymentReferenceData } from '../../../helpers/reconciliationHelpers';
import { RECONCILIATION_API_ROUTES } from '../../../configs/apiConfig'


import CompletedPaymentsTable from '../../../components/Reconciliation/CompletedPaymentsTable/CompletedPaymentsTable';
import StatusAlert from '../../../components/Alert/StatusAlert/StatusAlert';


export default function CompletedPayments() {
	const [reconciliationPaymentReferences, setReconciliationPaymentReferences] = useState(null)
	const [reconciliationPaymentData, setReconciliationPaymentData] = useState(null)
	const [searchedReconciliationPayment, setSearchedReconciliationPayment] = useState(null)
	const [loading, setLoading] = useState(true)
	const [currentPaymentRef, setCurrentPaymentRef] = useState(null)
	const [error, setError] = useState(false)

	const { username, userAccessToken } = useAuth()

	useEffect(() => {
		if (userAccessToken) {
			getPaymentReferences();
		}
	}, [userAccessToken]);

	async function getPaymentReferences() {
		setLoading(true);
		setError(false);
		try {
			const response = await fetch(RECONCILIATION_API_ROUTES.RECONCILIAITION_PAYMENT.GET_ALL_PAYMENT_REFS, {
				method: 'GET',
				cache: 'no-cache',
				headers: {
					"X-Authorization": userAccessToken,
					"X-User": username,
				}
			})
			if (!response.ok) {
				const errorMessage = await response.text()
				throw new Error(errorMessage)
			}
			const data = await response.json()
			setReconciliationPaymentReferences(prepareReconciliationPaymentReferenceData(data))
		} catch (error) {
			setError(true);
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	async function getPaymentDataByRef(paymentRefData) {
		setReconciliationPaymentData(null)
		setCurrentPaymentRef(paymentRefData.paymentRef)
		setLoading(true);
		setError(false);
		try {
			const response = await fetch(RECONCILIATION_API_ROUTES.RECONCILIAITION_PAYMENT.GET_BY_REF + `?paymentRef=${paymentRefData.paymentRef}`, {
				method: 'GET',
				cache: 'no-cache',
				headers: {
					"X-Authorization": userAccessToken,
					"X-User": username,
				},
			})
			if (!response.ok) {
				const errorMessage = await response.text()
				throw new Error(errorMessage)
			}
			const data = await response.json()
			setReconciliationPaymentData(prepareReconciliationPaymentData(data))
		} catch (error) {
			setError(true);
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	function getSearchOptions() {
		return reconciliationPaymentReferences.map((obj) => ({
			name: obj.paymentRef, 
			value: obj.paymentRef, 
		}));
	}

	return (
		<>
			<h1 className="mb-3 ml-2">Booking.com Completed Payments</h1>
			<AuthenticatedTemplate interactionType={InteractionType.Redirect}>
				{ loading && <Spinner color="secondary" type="grow" className="position-absolute m-auto" style={{ inset: '0', width: '150px', height: '150px'}}  /> }
				{ reconciliationPaymentReferences &&
					<>
						<div className="search-payments d-flex justify-content-between align-items-center mb-4">
							<SelectSearch
								options={getSearchOptions()}
								value={searchedReconciliationPayment}
								placeholder="Search for a payment reference"
								emptyMessage="No matching results"
								search
								onChange={(value) => {
									setSearchedReconciliationPayment(value)
									setReconciliationPaymentData(null)
								}}
								filterOptions={(options) => {
									const filter = fuzzySearch(options);
									return (q) => filter(q).slice(0, 20);
								}}
							/>
							<button
								onClick={() => {
									setSearchedReconciliationPayment(null)
									setReconciliationPaymentData(null)
								}}
								className="btn btn-danger ms-3"
							>
								Clear
							</button>
						</div>
						<CompletedPaymentsTable
							reconciliationPaymentReferences={reconciliationPaymentReferences}
							searchedReconciliationPayment={searchedReconciliationPayment}
							reconciliationPaymentData={reconciliationPaymentData}
							getPaymentData={getPaymentDataByRef}
							currentPaymentRef={currentPaymentRef}
						/>
					</>
				}
				<StatusAlert type="danger" show={error} hide={setError} />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<p>You need to sign in to see this page. Please wait for a redirect to the sign in page.</p>
			</UnauthenticatedTemplate>
		</>
	);
}