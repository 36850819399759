export const reconciliationTableHeading = [
    { key: "pay", label: "Pay"},
    { key: "bookingRef", label: "VP Reference"},
    { key: "checkInDate", label: "Check-in"},
    { key: "customerPaid", label: "Customer Paid", format: 'EUR'},
    { key: "expectedCommission", label: "Expected Com", format: 'EUR'},
    { key: "status", label: "Status"},
    { key: "reservationId", label: "Reservation No."},
    { key: "invoiceNumber", label: "Invoice Number"},
    { key: "arrivalDate", label: "File Arrival"},
    { key: "amountPaid", label: "File Amount", format: 'EUR'},
    { key: "commisionAmount", label: "File Com", format: 'EUR'},
    { key: "commisionPercentage", label: "File Com %"},
    { key: "reason", label: "Reason"},
    { key: "editableNote", label: "Notes"},
]

export const reconciliationPaymentTableHeading = [
    { key: "reservationId", label: "Reservation No." },
    { key: "bookingRef", label: "VP Reference" },
    { key: "arrival", label: "Arrival" },
    { key: "invoiceNumber", label: "Invoice Number" },
    { key: "hotelId", label: "Hotel Id"},
    { key: "propertyName", label: "Property Name"},
    { key: "amountPaid", label: "Amount Paid", format: 'EUR' },
    { key: "commisionAmount", label: "Commission Amount", format: 'EUR' },
    { key: "commisionPercentage", label: "Commission %" },
    { key: "status", label: "Status" },
    { key: "notes", label: "Notes" },
]

export const reconciliationPaymentReferencesTableHeading = [
    { key: "paymentRef", label: "Payment Reference" },
    { key: "checkedDate", label: "Checked Date" },
    { key: "checkedBy", label: "Checked By" },
    { key: "amount", label: "Amount" }
]

export const disputesExportHeading = [
    { key: "hotelId", label: "Hotel id"},
    { key: "reservationId", label: "Reservation number"},
    { key: "invoiceNumber", label: "Invoice number"},
    { key: "bookingRef", label: "Booking Reference"},
    { key: "propertyName", label: "Property name"},
    { key: "arrivalDate", label: "Arrival"},
    { key: "departureDate", label: "Departure"},
    { key: "bookerName", label: "Booker name"},
    { key: "commisionPercentage", label: "Commission %"},
    { key: "amountPaid", label: "Original Amount"},
    { key: "", label: "City Tax charged"},
    { key: "commisionAmount", label: "Commission amount"},
    { key: "", label: "Credit note Requested"},
    { key: "", label: "Correct Commission"},
    { key: "status", label: "Status"},
    { key: "guestRequest", label: "Guest request"},
]

export const paymentExportHeading = [
    { key: "invoiceNumber", label: "Invoice Number" },
    { key: "hotelId", label: "Hotel id"},
    { key: "propertyName", label: "Property name"},
    { key: "commisionAmount", label: "Commission Amount"},
]