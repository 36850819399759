import { ArrowDownSquare, ArrowDownSquareFill, ArrowUpSquareFill } from 'react-bootstrap-icons';

export default function SortingArrow(props) {
	const { appliedSorting, name, callback } = props;

	if (appliedSorting.column !== name) {
		return <ArrowDownSquare size={24} onClick={() => callback("asc", name)} />;
	} else if (appliedSorting.direction === "asc") {
		return <ArrowDownSquareFill size={24} onClick={() => callback("desc", name)} />;
	} else {
		return <ArrowUpSquareFill size={24} onClick={() => callback("asc", name)} />
	}
}