// eslint-disable-next-line import/no-extraneous-dependencies
import Fuse from 'fuse.js';

export default function fuzzySearch(options) {
    const fuse = new Fuse(options, {
        keys: ['name'],
        threshold: 0.1,
    });

    return (value) => {
        if (!value.length) {
            return options;
        }

        var results = fuse.search(value);
        var flattenedArray = [];

        if (results) {
            results.forEach((x => { flattenedArray.push({ name: x.item.name, value: x.item.value }) }))
        }
        return flattenedArray;
    };
}