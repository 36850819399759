import { Route, Routes } from 'react-router';
import Layout from './views/Layout/Layout';
import OTAPublishing from './views/OTAPublishing/OTAPublishing';
import PendingPayments from './views/Reconciliation/PendingPayments/PendingPayments';
import CompletedPayments from './views/Reconciliation/CompletedPayments/CompletedPayments';
import Home from './views/Home';
import './custom.css'

export default function App() {
	return (
		<Layout>
			<Routes>
				<Route exact path='/' element={<Home /> } />
				<Route exact path='/ota-publishing' element={<OTAPublishing /> } />
				<Route exact path='/reconciliation/pending-payments' element={<PendingPayments /> } />
				<Route exact path='/reconciliation/completed-payments' element={<CompletedPayments /> } />
			</Routes>

		</Layout>
	);
}
