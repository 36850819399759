import { Alert } from 'reactstrap';

import './StatusAlert.css'

export default function StatusAlert({ type, show, hide }) {

    const title = type === "danger" ? "Oops!" : "Success"
    const message = type === "danger" ? "Something went wrong please refresh try again, if the issue persists please contact the dev team." : "Successfully saved changes"
    return (
        <Alert className="status-alert-wrapper" color={type} isOpen={show} toggle={() => hide(false)}>
            <h6>{title}</h6>
            <p>{message}</p>
		</Alert>
    )
}