import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
export default function useAuth() {

	const [userAccessToken, setUserAccessToken] = useState("")
	const [username, setUsername] = useState("")

	const { instance, accounts, inProgress } = useMsal()

	useEffect(() => {
		if (inProgress === InteractionStatus.None && accounts.length > 0) {
			instance.acquireTokenSilent({
				account: accounts[0],
				scopes: ["User.Read"]
			}).then(response => {
				if (response.accessToken) {
					setUserAccessToken(response.accessToken || userAccessToken);
					setUsername(response.account.name || username);
				}
			}).catch((error) => {
				console.error("acquireTokenSilent error:", error);
			});
		} else if (inProgress === InteractionStatus.None && accounts.length === 0) {
			instance.loginRedirect();
		}
	}, [inProgress, accounts, instance])

	return { username, userAccessToken }
}