import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import useAuth from "../hooks/authenticationHooks";

export default function Home() {

	useAuth();

    return (
		<div>
			<AuthenticatedTemplate interactionType={InteractionType.Redirect}>
				<h4>Select a menu item to begin</h4>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<p>You need to sign in to see this page. Please wait for a redirect to the sign in page.</p>
			</UnauthenticatedTemplate>
        </div>
    );
}
