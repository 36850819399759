const RECONCILIATION_BASE_URI = {
    RECONCILIAITION: "reconciliation",
    RECONCILIAITION_PAYMENT: "reconciliationpayment",
}

export const RECONCILIATION_API_ROUTES = {
    RECONCILIAITION: {
        SAVE: `${RECONCILIATION_BASE_URI.RECONCILIAITION}/save`,
        CLEAR: `${RECONCILIATION_BASE_URI.RECONCILIAITION}/clear`,
        GET_ALL: `${RECONCILIATION_BASE_URI.RECONCILIAITION}/getall`,
        UPLOAD: `${RECONCILIATION_BASE_URI.RECONCILIAITION}/upload`,
    },
    RECONCILIAITION_PAYMENT: {
        SAVE: `${RECONCILIATION_BASE_URI.RECONCILIAITION_PAYMENT}/save`,
        GET_BY_REF: `${RECONCILIATION_BASE_URI.RECONCILIAITION_PAYMENT}/get`,
        GET_ALL_PAYMENT_REFS: `${RECONCILIATION_BASE_URI.RECONCILIAITION_PAYMENT}/getallreferences`,
    }
}